/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "sonner";

import { ApiContext } from "../providers/ApiProvider";
import { AuthContext } from "../providers/AuthProvider";

import { classNames } from "../utils/cssUtils";

import GeneralTab from "../components/UserProfile/GeneralTab";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import PasswordTab from "../components/UserProfile/PasswordTab";

const tabs = [
  { name: "General", value: "general" },
  { name: "Password", value: "password" },
];

const UserProfilePage = () => {
  const auth = useContext(AuthContext);
  const api = useContext(ApiContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [curTab, setCurTab] = useState("general");
  const [user, setUser] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    auth
      .currentUser()
      .then((u) => {
        setUser(u);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [auth]);

  const handleCancel = () => {
    navigate("/");
  };

  const handleChangeTab = (tab) => {
    setCurTab(tabs.find((t) => t.value === tab).value);
  };

  const handleUpdateProfile = (data) => {
    setIsLoading(false);
    api.client
      .put(
        `/users/${user.id}`,
        { user: data },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(() => {
        // TODO update current user data in AuthContext
        toast.success("Your information was updated successfully!");
        setIsLoading(false);
      })
      .catch(() => {
        toast.error("There was an error updating your information. Please try again.");
        setIsLoading(false);
      });
  };

  const handleChangePassword = (oldPwd, newPwd, confirmPwd) => {
    setIsLoading(false);
    api.client
      .put("/auth/password", {
        password: newPwd,
        password_confirmation: confirmPwd,
        current_password: oldPwd,
      })
      .then(() => {
        toast.success("Password updated successfully!");
        setIsLoading(false);
        navigate("/");
      })
      .catch(() => {
        toast.error("There was an error updating your password. Please try again.");
        setIsLoading(false);
      });
  };

  return (
    <div className="w-full rounded-lg bg-white shadow mx-5">
      <div className="px-4 py-5 sm:p-6">
        <div className="mx-auto flex max-w-4xl flex-col md:px-8 xl:px-0">
          <main className="flex-1">
            <div className="relative mx-auto max-w-4xl md:px-8 xl:px-0">
              <div className="pt-10 pb-16">
                <div className="px-4 sm:px-6 md:px-0">
                  <h1 className="text-3xl font-bold tracking-tight text-gray-900">Your Profile</h1>
                </div>
                <div className="px-4 sm:px-6 md:px-0">
                  <div className="py-6">
                    <div className="lg:block">
                      <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8">
                          {tabs.map((tab) => (
                            <a
                              key={tab.name}
                              onClick={() => handleChangeTab(tab.value)}
                              className={classNames(
                                tab.value === curTab
                                  ? "border-purple-500 text-purple-600"
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                              )}
                            >
                              {tab.name}
                            </a>
                          ))}
                        </nav>
                      </div>
                    </div>
                    {isLoading ? (
                      <LoadingSpinner />
                    ) : curTab === "general" ? (
                      <GeneralTab onCancel={handleCancel} onUpdate={handleUpdateProfile} user={user} />
                    ) : (
                      <PasswordTab onCancel={handleCancel} onUpdate={handleChangePassword} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default UserProfilePage;
