import { useContext, useEffect, useRef, useState } from "react";

import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption } from "@headlessui/react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { classNames } from "../../utils/cssUtils";

import { ApiContext } from "../../providers/ApiProvider";

const PatientSearchAutocomplete = (props) => {
  const { onSelect, light = false, keepPatient = false } = props;

  const api = useContext(ApiContext);

  const [query, setQuery] = useState("");
  const [patient, setPatient] = useState(null);
  const [patients, setPatients] = useState([]);

  const inRef = useRef();

  useEffect(() => {
    if (query.length < 2) return;

    api.client.get("/patients", { params: { query: query } }).then((resp) => {
      setPatients(resp.data.patients);
    });
  }, [api.client, query]);

  const comparePatient = (a, b) => a?.first_name.toLowerCase() === b?.first_name.toLowerCase();

  const handleSelect = (patient) => {
    if (!keepPatient) {
      setPatient(null);
    }
    setPatients([]);
    onSelect(patient);
  };

  return (
    <div className="w-full">
      <Combobox as="div" value={patient} by={comparePatient} onChange={handleSelect} onClose={() => setQuery("")}>
        <>
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <ComboboxInput
            placeholder="Search for a patient"
            className={classNames(
              "w-full rounded-md py-1.5 pl-10 pr-12 focus:ring-0 sm:text-sm sm:leading-6",
              light
                ? "ring-1 bg-white placeholder:text-slate-600 focus:text-slate-800"
                : "border-0 bg-gray-700 text-gray-300 placeholder:text-gray-400 focus:bg-white focus:text-gray-900"
            )}
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(patient) => patient?.name}
            ref={inRef}
          />

          {patients.length > 0 && (
            <ComboboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {patients.map((pt) => (
                <ComboboxOption
                  key={pt.id}
                  value={pt}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  <div className="flex items-center">
                    {pt.photo ? (
                      <img src={pt.photo} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                    ) : (
                      <span className="inline-block overflow-hidden h-6 w-6 rounded-full bg-gray-100">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    )}
                    <span className="ml-3 truncate">{pt.name}</span>
                  </div>
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </>
      </Combobox>
    </div>
  );
};

export default PatientSearchAutocomplete;
