import { useState } from "react";

import { RiAddCircleFill, RiAddCircleLine, RiArrowRightSLine } from "@remixicon/react";

const AddToPatientItem = (props) => {
  const { recommendation, patient, onAdd } = props;

  const label = 'Add "' + recommendation.content.substring(0, 50) + '..."';

  const [hoverAdd, setHoverAdd] = useState(false);

  return (
    <>
      {patient.photo ? (
        <img src={patient.photo} alt="" className="h-8 w-8 flex-none rounded-full" />
      ) : (
        <span className="inline-block overflow-hidden h-8 w-8 rounded-full bg-slate-100">
          <svg className="h-full w-full text-slate-300" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      )}
      <span className="ml-3 flex-auto truncate">{patient.name}</span>
      <span
        onMouseEnter={() => setHoverAdd(true)}
        onMouseLeave={() => setHoverAdd(false)}
        onClick={() => onAdd(patient)}
        data-tooltip-id="add-patient-recommendation"
        data-tooltip-content={label}
        className="cursor-pointer text-indigo-500 group-data-[focus]:block"
      >
        {hoverAdd ? <RiAddCircleFill size={20} /> : <RiAddCircleLine size={20} />}
      </span>
      <RiArrowRightSLine
        className="ml-3 h-5 w-5 flex-none text-slate-400 group-data-[focus]:block"
        aria-hidden="true"
      />
    </>
  );
};

export default AddToPatientItem;
