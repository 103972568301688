import { useState } from "react";

import { useFeatureFlagEnabled } from "posthog-js/react";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

import {
  RiArrowDownSFill,
  RiArrowDownSLine,
  RiArrowUpSFill,
  RiArrowUpSLine,
  RiDeleteBinFill,
  RiDeleteBinLine,
  RiEdit2Fill,
  RiEdit2Line,
  RiMore2Line,
  RiNotification2Fill,
  RiNotification2Line,
  RiNotificationOffFill,
  RiNotificationOffLine,
} from "@remixicon/react";

const Actions = (props) => {
  const { open, notify, onDelete, onEdit, onDetails, onNotifyChange } = props;

  const [hoverArticle, setHoverArticle] = useState(false);
  const [hoverDelete, setHoverDelete] = useState(false);
  const [hoverEdit, setHoverEdit] = useState(false);
  const [notifyHover, setNotifyHover] = useState(false);

  const flagEnabled = useFeatureFlagEnabled("action-item-tracking");

  return (
    <>
      <div className="hidden lg:relative lg:flex">
        <span
          onMouseEnter={() => setHoverDelete(true)}
          onMouseLeave={() => setHoverDelete(false)}
          onClick={onDelete}
          className="ml-2 cursor-pointer text-red-800"
        >
          {hoverDelete ? <RiDeleteBinFill className="h-5 w-5" /> : <RiDeleteBinLine className="h-5 w-5" />}
        </span>
        <span
          onMouseEnter={() => setHoverEdit(true)}
          onMouseLeave={() => setHoverEdit(false)}
          onClick={onEdit}
          className="ml-2 cursor-pointer text-indigo-800"
        >
          {hoverEdit ? <RiEdit2Fill className="h-5 w-5" /> : <RiEdit2Line className="h-5 w-5" />}
        </span>
        {flagEnabled && (
          <span
            onMouseEnter={() => setNotifyHover(true)}
            onMouseLeave={() => setNotifyHover(false)}
            onClick={onNotifyChange}
            className="ml-2 cursor-pointer"
          >
            {notify ? (
              notifyHover ? (
                <RiNotification2Fill className="text-slate-800 h-5 w-5" />
              ) : (
                <RiNotification2Line className="text-slate-800 h-5 w-5" />
              )
            ) : notifyHover ? (
              <RiNotificationOffFill className="text-slate-600 h-5 w-5" />
            ) : (
              <RiNotificationOffLine className="text-slate-700 h-5 w-5" />
            )}
          </span>
        )}
        <span
          onMouseEnter={() => setHoverArticle(true)}
          onMouseLeave={() => setHoverArticle(false)}
          onClick={onDetails}
          className="cursor-pointer text-slate-800"
        >
          {open ? (
            hoverArticle ? (
              <RiArrowUpSFill className="h-5 w-5" />
            ) : (
              <RiArrowUpSLine className="h-5 w-5" />
            )
          ) : hoverArticle ? (
            <RiArrowDownSFill className="h-5 w-5" />
          ) : (
            <RiArrowDownSLine className="h-5 w-5" />
          )}
        </span>
      </div>
      <Menu as="div" className="relative ml-3 sm:hidden">
        <div>
          <MenuButton className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <RiMore2Line className="h-5 w-5" />
          </MenuButton>
        </div>
        <MenuItems
          transition
          className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <MenuItem>
            <button
              type="button"
              onClick={onDetails}
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
            >
              Patient Resources
            </button>
          </MenuItem>
          <MenuItem>
            <button
              type="button"
              onClick={onNotifyChange}
              className="block px-4 py-2 text-sm text-slate-700 data-[focus]:bg-slate-100"
            >
              {notify ? "Disable Reminders" : "Enable Reminders"}
            </button>
          </MenuItem>
          <MenuItem>
            <button
              type="button"
              onClick={onDelete}
              className="block px-4 py-2 text-sm text-red-700 data-[focus]:bg-gray-100"
            >
              Remove from Patient
            </button>
          </MenuItem>
          <MenuItem>
            <button
              type="button"
              onClick={onEdit}
              className="block px-4 py-2 text-sm text-indigo-700 data-[focus]:bg-gray-100"
            >
              Edit Action Item
            </button>
          </MenuItem>
        </MenuItems>
      </Menu>
    </>
  );
};

export default Actions;
