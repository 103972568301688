import { useState } from "react";

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";

import { RiCloseLine, RiMenuLine } from "@remixicon/react";

const navigation = [
  {
    id: 0,
    name: "For Patients",
    href: "https://caspiahealth.com/",
    target: "_blank",
  },
  {
    id: 1,
    name: "LM Provider Map",
    href: "https://mapper.caspiahealth.com/",
    target: "_blank",
  },
  {
    id: 2,
    name: "Pricing",
    href: "/#pricing",
    target: "_self",
  },
  {
    id: 3,
    name: "Newsletter",
    href: "/#newsletter",
    target: "_self",
  },
  {
    id: 4,
    name: "Contact Us",
    href: "/#contact",
    target: "_self",
  },
  {
    id: 5,
    name: "About",
    href: "/#about",
    target: "_self",
  },
];

const AnonTopNav = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <Disclosure as="nav" className="bg-slate-800">
      <div aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 px-3 lg:px-8">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 px-1.5 pt-2 pb-4">
            <span className="sr-only">Pro-Patient Tech</span>
            <img className="hidden lg:block h-auto w-64" src="/images/logo_text_white.png" alt="Pro-Patient Tech" />
            <img className="lg:hidden h-16 w-auto py-2" src="/images/favicon.png" alt="Pro-Patient Tech" />
          </a>
        </div>
        <div className="hidden lg:flex lg:justify-between">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              target={item.target}
              rel={item.target === "_blank" ? "noreferrer" : ""}
              className="px-4 py-2 text-sm font-semibold leading-6 text-slate-200 hover:bg-slate-700 hover:text-white hover:rounded-full"
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className="flex flex-1 items-center justify-end gap-x-6">
          <a href="/login" className="hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-slate-200">
            Log in
          </a>
          <a
            href="/register"
            className="rounded-full bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sign up free
          </a>
        </div>
        <div className="flex lg:hidden">
          <DisclosureButton
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-slate-300"
          >
            <span className="sr-only">Open main menu</span>
            <RiMenuLine size={24} aria-hidden="true" className="block group-data-open]:hidden" />
            <RiCloseLine size={24} aria-hidden="true" className="hidden group-data-open]:block" />
          </DisclosureButton>
        </div>
      </div>
      <DisclosurePanel open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {navigation.map((item) => (
            <DisclosureButton
              key={item.name}
              as="a"
              href={item.href}
              className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
            >
              {item.name}
            </DisclosureButton>
          ))}

          <Disclosure
            as="a"
            href="/login"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Log in
          </Disclosure>
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
};

export default AnonTopNav;
