import { useContext, useEffect, useState } from "react";

import { Tooltip } from "react-tooltip";
import { useHits } from "react-instantsearch";

import { AuthContext } from "../../../providers/AuthProvider";

import LoadingSpinner from "../../UI/LoadingSpinner";

import Pagination from "./Pagination";

const SearchResultList = (props) => {
  const { resultComponent: ResultComponent } = props;

  const auth = useContext(AuthContext);

  const { onAdd, ...searchProps } = props; // useHits doesn't like onAdd passed into it :|

  const { results } = useHits(searchProps);

  const [isLoading, setIsLoading] = useState(true);
  const [canAddToPatient, setCanAddToPatient] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    auth
      .currentUser()
      .then((u) => {
        setCanAddToPatient(() => {
          return !!u.plan.features.find((f) => f.name === "Action Plan Management" && f.is_enabled === true);
        });
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [auth]);

  if (results.hits.length === 0) return "";

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <ul className="w-full divide-y divide-slate-100 py-3 px-2">
            {results.hits.map((item, idx) => (
              <ResultComponent key={idx} item={item} onAdd={onAdd} canAddToPatient={canAddToPatient} />
            ))}
            <li className="relative flex items-center justify-between border-t border-slate-200 bg-white px-6 py-3">
              <Pagination />
            </li>
          </ul>
          <Tooltip id="actions-tooltip" />
        </>
      )}
    </>
  );
};

export default SearchResultList;
