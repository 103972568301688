import { forwardRef } from "react";

import { classNames } from "../../utils/cssUtils";

import LoadingSpinner from "./LoadingSpinner";

const TextArea = forwardRef(function TextArea(props, ref) {
  const {
    errorMessage,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    rows,
    value,
    isLoading = false,
    isValid = true,
    required = false,
    placeHolder = "",
    maxWLg = false,
    wFull = false,
  } = props;

  const handleChange = (e) => {
    e.preventDefault();

    if (!!onChange && typeof onChange === "function") {
      onChange(e.target.value);
    }
  };

  const handleBlur = (e) => {
    e.preventDefault();

    if (!!onBlur && typeof onBlur === "function") {
      onBlur(e.target.value);
    }
  };

  const handleFocus = (e) => {
    e.preventDefault();

    if (!!onFocus && typeof onFocus === "function") {
      onFocus(e.target.value);
    }
  };

  return (
    <>
      {!!label && (
        <div>
          <label htmlFor={name} className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
            {label}
          </label>
        </div>
      )}
      <div className={classNames("relative mt-1 rounded-md shadow-sm sm:col-span-2", wFull ? "w-full" : "")}>
        <textarea
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          ref={ref}
          id={name}
          name={name}
          required={required}
          rows={rows}
          placeholder={placeHolder}
          className={classNames(
            "block w-full rounded-md shadow-sm sm:text-sm border-gray-300",
            maxWLg ? "max-w-lg" : "",
            !isValid
              ? "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : "focus:border-indigo-500 focus:ring-indigo-500"
          )}
          defaultValue={value}
        />
        {!isValid && (
          <p className="mt-2 text-sm text-red-600" id="question-error">
            {errorMessage}
          </p>
        )}
        {isLoading && (
          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5">
              <div className="flex items-center">
                <span
                  type="button"
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <LoadingSpinner small={true} showLoadingText={false} />
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
});

export default TextArea;
