import { useContext, useEffect, useState } from "react";

import { toast } from "sonner";

import { RiMailSendLine } from "@remixicon/react";

import { ApiContext } from "../../providers/ApiProvider";
import { isEmailValid } from "../../utils/validators";
import Input from "../UI/Input";
import TextArea from "../UI/TextArea";

const Contact = () => {
  const api = useContext(ApiContext);

  const [form, setForm] = useState({
    name: { value: "", touched: false, valid: true },
    email: { value: "", touched: false, valid: true },
    message: { value: "", touched: false, valid: true },
  });
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (!form.email.touched) return;

    setForm((prev) => ({ ...prev, email: { ...prev.email, valid: isEmailValid(form.email.value) } }));
  }, [form.email]);

  useEffect(() => {
    if (!form.email.touched) return;

    setFormValid(form.email.valid);
  }, [form.email]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    api.client
      .post("/contact_us", {
        name: form.name.value,
        email: form.email.value,
        message: form.message.value,
      })
      .then(() => {
        toast.success("Thanks for contacting us. We'll get back to you shortly.");
        e.target.reset();
      });
  };

  return (
    <div id="contact" className="relative isolate bg-slate-50">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-20 lg:static lg:px-8">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
              <svg
                aria-hidden="true"
                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
              >
                <defs>
                  <pattern
                    x="100%"
                    y={-1}
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={200}
                    height={200}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <rect fill="white" width="100%" height="100%" strokeWidth={0} />
                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0} />
              </svg>
            </div>
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              Get in touch
            </h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              We're just one click away to help you take your practice from great to incredible.
            </p>
            <div className="flex flex-col mt-10">
              <div>
                <img alt="" src="images/nupur.jpg" className="inline-block h-16 w-16 rounded-full" />
              </div>
              <h3 className="mt-4 text-xl font-semibold text-gray-900">
                Hi, I'm Dr. Nupur Garg! Let's chat about how we can help add Lifestyle Medicine to your practice.
              </h3>
              <div>
                <a
                  href="mailto:info@propatienttech.com"
                  className="flex items-center mt-4 text-indigo-700 hover:text-indigo-600 hover:underline"
                >
                  <RiMailSendLine size={24} className="mr-2" />
                  Email me directly
                </a>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="px-6 pb-20 pt-20 lg:px-8">
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="flex flex-col gap-x-8 gap-y-4">
              <Input
                type="text"
                name="name"
                label="Name"
                placeholder="Your name"
                value={form.name.value}
                isValid={form.name.valid}
                onChange={(val) => setForm((prev) => ({ ...prev, name: { ...prev.name, value: val, touched: true } }))}
                onBlur={() => setForm((prev) => ({ ...prev, name: { ...prev.name, touched: true } }))}
                errorMessage="Name cannot be blank!"
              />
              <Input
                type="email"
                name="email"
                label="Email"
                placeholder="Your email"
                value={form.email.value}
                isValid={form.email.valid}
                onChange={(val) =>
                  setForm((prev) => ({ ...prev, email: { ...prev.email, value: val, touched: true } }))
                }
                onBlur={() => setForm((prev) => ({ ...prev, email: { ...prev.email, touched: true } }))}
                errorMessage="Email is invalid!"
              />
              <TextArea
                name="message"
                label="Message"
                value={form.message.value}
                rows={4}
                onChange={(val) =>
                  setForm((prev) => ({ ...prev, message: { ...prev.message, value: val, touched: true } }))
                }
              />
            </div>
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                disabled={!formValid}
                className="disabled:cursor-not-allowed rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm disabled:bg-indigo-500 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Send message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
