import { useState } from "react";

import { usePostHog } from "posthog-js/react";

import { useInstantSearch, useSearchBox } from "react-instantsearch";

import { RiCloseCircleFill, RiCloseCircleLine, RiSearchLine } from "@remixicon/react";

import LoadingSpinner from "../../UI/LoadingSpinner";
import Filter from "./Filter";

const CustomSearchInput = (props) => {
  const { status } = useInstantSearch();
  const { query, refine, clear } = useSearchBox(props);

  const posthog = usePostHog();

  const [hoverClear, setHoverClear] = useState(false);

  const isSearchStalled = status === "stalled";

  const setQuery = (e) => {
    e.preventDefault();
    e.stopPropagation();

    posthog?.capture("recommendation_search", { query: e.currentTarget.value });

    refine(e.currentTarget.value);
  };

  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();

    clear();
  };

  return (
    <>
      <div className="flex flex-col w-full lg:w-3/4">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <input
            id="search"
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            maxLength={512}
            placeholder="Search Action Plan Recommendations"
            className="block w-full pl-4 text-base leading-6 rounded-md border-0 bg-slate-50 focus:bg-white py-3.5 pr-10 text-slate-900 ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            value={query}
            onChange={setQuery}
            onBlur={setQuery}
          />
          <div className="absolute inset-y-0 right-0 flex items-center px-3">
            {isSearchStalled ? (
              <LoadingSpinner small color="text-indigo-800" showLoadingText={false} />
            ) : !!query ? (
              <span
                onMouseEnter={() => setHoverClear(true)}
                onMouseLeave={() => setHoverClear(false)}
                onClick={handleClear}
                className="cursor-pointer text-slate-600"
              >
                {hoverClear ? <RiCloseCircleFill size={24} /> : <RiCloseCircleLine size={24} />}
              </span>
            ) : (
              <RiSearchLine size={24} className="text-slate-600" />
            )}
          </div>
        </div>
        {!!query && query.length > 0 && <Filter />}
      </div>
    </>
  );
};

export default CustomSearchInput;
