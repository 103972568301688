import { RiBookReadFill, RiTodoLine } from "@remixicon/react";

const features = [
  {
    title: "Lifestyle Medicine action items with relevant references",
    subtitle: "Each of the action items has at least 3 clickable resources to get the patient started.",
    icon: (
      <>
        <svg className="h-9 w-9" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path d="M4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H14C14.5523 21 15 20.5523 15 20V15.2973L15.9995 19.9996C16.1143 20.5398 16.6454 20.8847 17.1856 20.7699L21.0982 19.9382C21.6384 19.8234 21.9832 19.2924 21.8684 18.7522L18.9576 5.0581C18.8428 4.51788 18.3118 4.17304 17.7716 4.28786L14.9927 4.87853C14.9328 4.38353 14.5112 4 14 4H10C10 3.44772 9.55228 3 9 3H4ZM10 6H13V14H10V6ZM10 19V16H13V19H10ZM8 5V15H5V5H8ZM8 17V19H5V17H8ZM17.3321 16.6496L19.2884 16.2338L19.7042 18.1898L17.7479 18.6057L17.3321 16.6496ZM16.9163 14.6933L15.253 6.86789L17.2092 6.45207L18.8726 14.2775L16.9163 14.6933Z"></path>
        </svg>
      </>
    ),
  },
  {
    title: "User friendly patient app (web & iOS)",
    subtitle: "Easy and ready access to assigned action items on a user-friendly mobile platform.",
    icon: <RiBookReadFill size={32} />,
  },
  {
    title: "Patient-facing resources & tracking for each action item",
    subtitle:
      "Patient's ability to track positive and negative action items to help patients achieve their health goals.",
    icon: <RiTodoLine size={32} />,
  },
];

const Features = () => {
  return (
    <div className="flex flex-col bg-indigo-100 pt-12 pb-10 px-8 lg:px-36">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="font-display text-3xl tracking-tight text-slate-700 sm:text-4xl">
          More tools to help scale an LM practice:
        </h2>
      </div>
      <div className="grid grid-rows-3 grid-cols-1 grid-flow-row lg:grid-rows-1 lg:grid-cols-3 lg:grid-flow-col justify-center mt-5 px-10 py-5 lg:gap-x-5">
        {features.map((feature, idx) => (
          <div key={idx} className="flex flex-col space-y-3">
            <span className="text-indigo-800">{feature.icon}</span>
            <span className="text-xl font-semibold">{feature.title}</span>
            {/* <span className="text-slate-700">{feature.subtitle}</span> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
