import { format } from "date-fns";

import { RiExternalLinkLine } from "@remixicon/react";

const RecommendationResources = (props) => {
  const { resources, reviewedAt } = props;

  return (
    <div className="flex flex-col w-full p-5">
      <div className="flex justify-between">
        <h2 className="font-semibold text-slate-900">Patient Resources</h2>
        {reviewedAt && (
          <span className="text-sm text-slate-800">Last updated: {format(reviewedAt, "MMM d, yyyy")}</span>
        )}
      </div>
      <ul className="divide-y divide-slate-100">
        {resources.map((r, idx) => (
          <li key={idx} className="relative w-full flex items-center justify-between px-2 py-2">
            <div className="flex text-sm leading-6 text-slate-900 items-center space-x-2 hover:text-indigo-500">
              <RiExternalLinkLine className="flex-none h-5 w-5" />
              <a href={r.link} className="hover:underline" target="_blank" rel="noreferrer">
                {r.content}
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecommendationResources;
