import clsx from "clsx";

import { useHits } from "react-instantsearch";

import CustomSearchInput from "../components/Dashboard/Search/CustomSearchInput";
import RecommendationResult from "../components/Dashboard/Search/Recommendations/RecommendationResult";
import SearchResultList from "../components/Dashboard/Search/SearchResultList";

const DashboardPage = () => {
  const { results } = useHits();

  return (
    <div className="flex flex-col w-full">
      <div
        className={clsx(
          "flex flex-col w-full justify-center items-center sm:mx-0",
          results.hits.length > 0 ? "bg-indigo-50 shadow mt-5 px-2" : "mt-80 mx-5"
        )}
      >
        <div className={clsx("flex justify-center w-full", results.hits.length === 0 && "max-w-7xl")}>
          <CustomSearchInput />
        </div>
        {results.hits.length === 0 && (
          <div className="flex items-center mt-5 text-slate-700">
            Search for a pillar like sleep, medical condition like diabetes, or what you want to achieve like weight
            loss.
          </div>
        )}
      </div>

      {results.hits.length !== 0 && (
        <div className="flex flex-col mt-5 px-2 lg:mx-10 items-center">
          <div className="flex flex-col items-center lg:w-3/4 bg-white shadow-sm ring-1 ring-slate-900/5 rounded-xl mb-5">
            <SearchResultList resultComponent={RecommendationResult} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardPage;
