import clsx from "clsx";

const FilterItem = (props) => {
  const { count, isActive, onChange, pillar } = props;

  const buildIcon = (Icon) => {
    return <Icon size={24} className="p-1" />;
  };

  const activeClasses = () => {
    switch (pillar.color) {
      case "green":
        return "text-green-600 bg-green-100 border-green-500";
      case "yellow":
        return "text-yellow-600 bg-yellow-100 border-yellow-500";
      case "blue":
        return "text-blue-600 bg-blue-100 border-blue-500";
      case "orange":
        return "text-orange-600 bg-orange-100 border-orange-500";
      case "purple":
        return "text-purple-600 bg-purple-100 border-purple-500";
      case "red":
        return "text-red-600 bg-red-100 border-red-500";
      case "stone":
        return "text-stone-600 bg-stone-100 border-stone-500";
      case "cyan":
        return "text-cyan-600 bg-cyan-100 border-cyan-500";
      default:
        return "text-slate-600 bg-slate-100 border-slate-500";
    }
  };

  const activeCountClasses = () => {
    switch (pillar.color) {
      case "green":
        return "ring-green-400";
      case "yellow":
        return "ring-yellow-400";
      case "blue":
        return "ring-blue-400";
      case "orange":
        return "ring-orange-400";
      case "purple":
        return "ring-purple-400";
      case "red":
        return "ring-red-400";
      case "stone":
        return "ring-stone-400";
      case "cyan":
        return "ring-cyan-400";
      default:
        return "ring-slate-400";
    }
  };

  return (
    <span
      key={pillar.label}
      onClick={onChange}
      className={clsx(
        "cursor-pointer m-1 flex items-center rounded-full border py-1.5 px-3 text-sm font-medium hover:shadow-md",
        isActive ? activeClasses() : "text-slate-500 bg-slate-50 border-slate-200"
      )}
    >
      {buildIcon(pillar.icon)}
      <span>{pillar.label}</span>
      <span
        className={clsx(
          "ml-1.5 ring-1 rounded-full px-1.5 py-0.5 text-xs font-semibold tabular-nums",
          isActive ? activeCountClasses() : "ring-slate-400 "
        )}
      >
        {count}
      </span>
    </span>
  );
};

export default FilterItem;
