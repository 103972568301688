import { createContext, useCallback, useState } from "react";

const ActionPlanContext = createContext({
  actionItems: [],
  add: (item) => {},
  remove: (id) => {},
  update: (item) => {},
  bulkAdd: (data) => {},
});

const ActionPlanProvider = ({ children }) => {
  const [actionItems, setActionItems] = useState([]);

  const add = useCallback((item) => {
    setActionItems((prev) => {
      return [...prev, item];
    });
  }, []);

  const remove = useCallback((id) => {
    setActionItems((prev) => {
      const res = prev.filter((item) => item.id !== id);
      return [...res];
    });
  }, []);

  const update = useCallback((item) => {
    setActionItems((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === item.id) {
          return item;
        } else {
          return rec;
        }
      });

      return [...res];
    });
  }, []);

  const bulkAdd = useCallback((data) => {
    setActionItems(data);
  }, []);

  const contextValue = {
    actionItems,
    add: useCallback((item) => add(item), [add]),
    remove: useCallback((id) => remove(id), [remove]),
    update: useCallback((item) => update(item), [update]),
    bulkAdd: useCallback((data) => bulkAdd(data), [bulkAdd]),
  };

  return <ActionPlanContext.Provider value={contextValue}>{children}</ActionPlanContext.Provider>;
};

export { ActionPlanContext, ActionPlanProvider };
