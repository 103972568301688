import clsx from "clsx";

function CheckIcon({ className, ...props }) {
  return (
    <svg aria-hidden="true" className={clsx("h-6 w-6 flex-none fill-current stroke-current", className)} {...props}>
      <path
        d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
        strokeWidth={0}
      />
      <circle cx={12} cy={12} r={8.25} fill="none" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

function Plan({ name, price, description, href, features, cta, featured = false }) {
  return (
    <section
      className={clsx(
        "flex flex-col rounded-3xl px-6 sm:px-8",
        featured ? "order-first bg-blue-600 py-8 lg:order-none" : "lg:py-8"
      )}
    >
      <h3 className="mt-5 font-display text-lg text-white">{name}</h3>
      <p className={clsx("mt-2 text-base", featured ? "text-white" : "text-slate-400")}>{description}</p>
      <p className="order-first font-display text-5xl font-light tracking-tight text-white">{price}</p>
      <ul
        className={clsx("order-last mt-10 flex flex-col gap-y-3 text-sm", featured ? "text-white" : "text-slate-200")}
      >
        {features.map((feature) => (
          <li key={feature} className="flex">
            <CheckIcon className={featured ? "text-white" : "text-slate-400"} />
            <span className="ml-4">{feature}</span>
          </li>
        ))}
      </ul>
      <a
        href={href}
        color="white"
        className={clsx(
          "mt-8 group inline-flex items-center justify-center rounded-full py-4 px-4 text-base focus:outline-none focus-visible:outline-white",
          featured
            ? "font-semibold focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600"
            : "ring-1 ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400"
        )}
        aria-label={`Get started with the ${name} plan for ${price}`}
      >
        {cta}
      </a>
    </section>
  );
}

const Pricing = () => {
  return (
    <section id="pricing" aria-label="Pricing" className="bg-slate-900 py-20 sm:py-32">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            <span className="relative whitespace-nowrap">
              <span className="relative">Affordable Tools,</span>
            </span>{" "}
            for everyone.
          </h2>
        </div>
        <div className="-mx-4 mt-16 grid max-w-2xl grid-cols-1 gap-y-10 sm:mx-auto lg:-mx-8 lg:max-w-none lg:grid-cols-4 xl:mx-0 xl:gap-x-8">
          <Plan
            name="Recommendation Library"
            price="Free"
            description="Just need recommendations for your own action plans? This is the option for you."
            href="/register"
            features={[
              "All recommendations",
              "Smart recommendation search",
              "References for each recommendation",
              "Favorite recommendations (coming soon)",
              "Group recommendations into your own collections (coming soon)",
              "Share recommendations (coming soon)",
            ]}
            cta="Sign Up Free"
          />
          <Plan
            featured
            name="Small Practice (up to 300 patients)"
            price="$20/mon"
            description="Action planning & tracking for your Lifestyle Medicine practice."
            href="/register"
            features={[
              "Everything in the Recommendation Library",
              "300 Patients included",
              "Patient resources with each recommendation",
              "Patient portal",
              "Patient iOS app (Android in 2025)",
              "Healthy Today Newsletter for Patients",
            ]}
            cta="Try Free for 30 Days"
          />
          <Plan
            name="Large Practice (up to 1000 patients)"
            price="$50/mon"
            description="Action planning & tracking for your (Larger) Lifestyle Medicine practice."
            href="/register"
            features={[
              "Everything in the Recommendation Library",
              "1000 Patients included",
              "Patient resources with each recommendation",
              "Patient portal",
              "Patient iOS app (Android in 2025)",
              "Healthy Today Newsletter for Patients",
            ]}
            cta="Try Free for 30 Days"
          />
          <Plan
            name="Enterprise"
            price="Custom"
            description="Integrate with your EHR and get the best of both worlds."
            href="mailto:misha@propatienttech.com?subject=Enterprise plan"
            features={[
              "Integrate our tools with your platform",
              "Bi-directional data exchange",
              "Custom integrations",
              "White-labeled",
              "API access",
            ]}
            cta="Contact Us"
          />
        </div>
      </div>
    </section>
  );
};

export default Pricing;
