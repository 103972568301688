/* eslint-disable jsx-a11y/no-redundant-roles */
import AnonTopNav from "../components/Layout/AnonTopNav";
import Footer from "../components/UI/Footer";

const CookiePage = () => {
  return (
    <div className="bg-white">
      <AnonTopNav />
      <main>
        <div className="mx-10">
          <h1 className="text-3xl font-semibold w-full text-center my-5">Cookie Policy</h1>
          <p className="px-5 py-6 text-sm">
            <strong>Last Modified:</strong> February 1, 2024
          </p>
          <p className="px-5 py-6 text-sm">
            Please read this cookie policy ("cookie policy", "policy") carefully before using [website] website
            ("website", "service") operated by [name] ("us", 'we", "our").
          </p>
          <h2 className="text-lg font-medium w-full">What are cookies?</h2>
          <p className="px-5 py-6 text-sm">
            Cookies are simple text files that are stored on your computer or mobile device by a website's server. Each
            cookie is unique to your web browser. It will contain some anonymous information such as a unique
            identifier, website's domain name, and some digits and numbers.
          </p>
          <h2 className="text-lg font-medium w-full">What types of cookies do we use?</h2>
          <h3 className="text-base font-medium w-full">Necessary cookies</h3>
          <p className="px-5 py-6 text-sm">
            Necessary cookies allow us to offer you the best possible experience when accessing and navigating through
            our website and using its features. For example, these cookies let us recognize that you have created an
            account and have logged into that account.
          </p>
          <h3 className="text-base font-medium w-full">Functionality cookies</h3>
          <p className="px-5 py-6 text-sm">
            Functionality cookies let us operate the site in accordance with the choices you make. For example, we will
            recognize your username and remember how you customized the site during future visits.
          </p>
          <h3 className="text-base font-medium w-full">Analytical cookies</h3>
          <p className="px-5 py-6 text-sm">
            These cookies enable us and third-party services to collect aggregated data for statistical purposes on how
            our visitors use the website. These cookies do not contain personal information such as names and email
            addresses and are used to help us improve your user experience of the website.
          </p>
          <h3 className="text-base font-medium w-full">How to delete cookies?</h3>
          <p className="px-5 py-6 text-sm">
            If you want to restrict or block the cookies that are set by our website, you can do so through your browser
            setting. Alternatively, you can visit <a href="www.internetcookies.com">www.internetcookies.com</a>, which
            contains comprehensive information on how to do this on a wide variety of browsers and devices. You will
            find general information about cookies and details on how to delete cookies from your device.
          </p>
          <h3 className="text-base font-medium w-full">Contacting us</h3>
          <p className="px-5 py-6 text-sm">
            If you have any questions about this policy or our use of cookies, please contact us at{" "}
            <a href="mailto:info@propatienttech.com">info@propatienttech.com</a>.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default CookiePage;
