import { useEffect, useState } from "react";

import { isEmailValid, isPasswordValid } from "../../utils/validators";

import Input from "../UI/Input";
import LoadingSpinner from "../UI/LoadingSpinner";
import PasswordInput from "../UI/PasswordInput";

const UserInfoForm = (props) => {
  const { onSubmit, isLoading } = props;

  const [form, setForm] = useState({
    organization: { value: "", error: false, touched: false },
    firstName: { value: "", error: false, touched: false },
    lastName: { value: "", error: false, touched: false },
    email: { value: "", error: false, touched: false },
    password: { value: "", error: false, touched: false },
    agreed: { value: false, error: false, touched: true },
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (!form.organization.touched) return;

    setForm((prev) => ({
      ...prev,
      organization: { ...prev.organization, error: prev.organization.value.trim().length < 1 },
    }));
  }, [form.organization.touched, form.organization.value]);

  useEffect(() => {
    if (!form.firstName.touched) return;

    setForm((prev) => ({ ...prev, firstName: { ...prev.firstName, error: prev.firstName.value.trim().length < 1 } }));
  }, [form.firstName.touched, form.firstName.value]);

  useEffect(() => {
    if (!form.lastName.touched) return;

    setForm((prev) => ({ ...prev, lastName: { ...prev.lastName, error: prev.lastName.value.trim().length < 1 } }));
  }, [form.lastName.touched, form.lastName.value]);

  useEffect(() => {
    if (!form.email.touched) return;

    setForm((prev) => ({ ...prev, email: { ...prev.email, error: !isEmailValid(prev.email.value) } }));
  }, [form.email.touched, form.email.value]);

  useEffect(() => {
    if (!form.password.touched) return;

    setForm((prev) => ({ ...prev, password: { ...prev.password, error: !isPasswordValid(prev.password.value) } }));
  }, [form.password.touched, form.password.value]);

  useEffect(() => {
    setForm((prev) => ({ ...prev, agreed: { ...prev.agreed, error: !prev.agreed.value } }));
  }, [form.agreed.value]);

  useEffect(() => {
    if (Object.values(form).every((field) => !field.touched)) {
      setIsFormValid(false);
      return;
    }

    setIsFormValid(Object.values(form).reduce((acc, field) => acc && !field.error, true));
  }, [form]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onSubmit({
      organization: form.organization.value,
      first_name: form.firstName.value,
      last_name: form.lastName.value,
      email: form.email.value,
      password: form.password.value,
      password_confirmation: form.password.value,
      accepted_terms: form.agreed.value,
    });
  };

  return (
    <form onSubmit={handleSubmit} disabled={isFormValid} className="space-y-6 sm:space-y-5">
      <Input
        type="text"
        name="organization"
        placeholder="Name of your business/practice"
        label="Your business/practice name"
        autoComplete="organization"
        value={form.organization.value}
        isValid={!form.organization.error}
        onChange={(val) => setForm((prev) => ({ ...prev, organization: { ...prev.organization, value: val } }))}
        onBlur={() => setForm((prev) => ({ ...prev, organization: { ...prev.organization, touched: true } }))}
        errorMessage="Your business/practice name is required"
      />

      <Input
        type="text"
        name="firstName"
        placeholder="First Name"
        label="First Name"
        autoComplete="given-name"
        value={form.firstName.value}
        isValid={!form.firstName.error}
        onChange={(val) => setForm((prev) => ({ ...prev, firstName: { ...prev.firstName, value: val } }))}
        onBlur={() => setForm((prev) => ({ ...prev, firstName: { ...prev.firstName, touched: true } }))}
        errorMessage="First name cannot be blank!"
      />

      <Input
        type="text"
        name="lastName"
        placeholder="Last Name"
        label="Last Name"
        autoComplete="family-name"
        value={form.lastName.value}
        isValid={!form.lastName.error}
        onChange={(val) => setForm((prev) => ({ ...prev, lastName: { ...prev.lastName, value: val } }))}
        onBlur={() => setForm((prev) => ({ ...prev, lastName: { ...prev.lastName, touched: true } }))}
        errorMessage="Last name cannot be blank!"
      />

      <Input
        type="email"
        name="email"
        placeholder="you@your-clinic.com"
        label="Your Email"
        autoComplete="email"
        value={form.email.value}
        isValid={!form.email.error}
        onChange={(val) => setForm((prev) => ({ ...prev, email: { ...prev.email, value: val } }))}
        onBlur={() => setForm((prev) => ({ ...prev, email: { ...prev.email, touched: true } }))}
        errorMessage="Please provide valid email!"
      />

      <PasswordInput
        name="password"
        label="Password (min 8 characters)"
        autoComplete="new-password"
        value={form.password.value}
        isValid={!form.password.error}
        onChange={(val) => setForm((prev) => ({ ...prev, password: { ...prev.password, value: val } }))}
        onBlur={() => setForm((prev) => ({ ...prev, password: { ...prev.password, touched: true } }))}
        errorMessage="Password has to be at least 8 characters!"
      />

      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            id="agree"
            name="agree"
            type="checkbox"
            checked={form.agreed.value}
            value={form.agreed.value}
            onChange={(e) => setForm((prev) => ({ ...prev, agreed: { ...prev.agreed, value: e.target.checked } }))}
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label htmlFor="agree" className="font-medium text-gray-900">
            I have read and agree to Pro-Patient Tech's{" "}
            <a href="/terms" className="hover:underline text-indigo-800">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="/privacy" className="hover:underline text-indigo-800">
              Privacy Statement
            </a>
            .
          </label>
        </div>
      </div>

      <button
        type="submit"
        disabled={!isFormValid || isLoading}
        className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 disabled:cursor-not-allowed enabled:text-white disabled:text-slate-500 enabled:bg-indigo-600 disabled:bg-indigo-300 enabled:hover:bg-indigo-500 enabled:focus-visible:outline enabled:focus-visible:outline-2 enabled:focus-visible:outline-offset-2 enabled:focus-visible:outline-indigo-600"
      >
        {isLoading ? <LoadingSpinner small color="text-indigo-700" showLoadingText={false} /> : <>Continue</>}
      </button>
    </form>
  );
};

export default UserInfoForm;
