import clsx from "clsx";

import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";

import { RiCheckLine, RiExpandUpDownLine } from "@remixicon/react";

// Expects objects to be {id: <Int>, name: <String>, value: <Any>}
const SelectBox = (props) => {
  const { activeValue, values, onChange, optMinW = "" } = props;

  return (
    <Listbox value={activeValue} onChange={onChange} name="questionType">
      <>
        <div className="relative w-full">
          <ListboxButton
            className={clsx(
              "relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6",
              optMinW
            )}
          >
            <span className="block">{activeValue.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <RiExpandUpDownLine className="h-5 w-5 text-slate-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <ListboxOptions
            transition
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
          >
            {values.map((value) => (
              <ListboxOption
                key={value.id}
                value={value}
                className={clsx(
                  "group relative cursor-default select-none py-2 pl-3 pr-9 text-slate-900 data-[focus]:bg-indigo-600 data-[focus]:text-white",
                  optMinW
                )}
              >
                <>
                  <span className="block truncate font-normal group-data-[selected]:font-semibold">{value.name}</span>

                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                    <RiCheckLine className="h-5 w-5" aria-hidden="true" />
                  </span>
                </>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </>
    </Listbox>
  );
};

export default SelectBox;
