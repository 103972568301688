import { useContext, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { usePostHog } from "posthog-js/react";

import { Tooltip } from "react-tooltip";

import { toast } from "sonner";

import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  DialogBackdrop,
} from "@headlessui/react";

import { RiSearch2Line, RiUserLine } from "@remixicon/react";

import { ApiContext } from "../../../providers/ApiProvider";

import LoadingSpinner from "../../UI/LoadingSpinner";

import EditRecommendation from "./Recommendations/EditRecommendation";

import AddToPatientItem from "./AddToPatientItem";

const AddToPatientDialog = (props) => {
  const { open, onClose, recommendation } = props;

  const toolTipContent = 'Assign "' + recommendation.content.substring(0, 50) + '..."';

  const navigate = useNavigate();

  const posthog = usePostHog();

  const api = useContext(ApiContext);

  const [patients, setPatients] = useState([]);
  const [patientsLoading, setPatientsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [recentPatients, setRecentPatients] = useState([]);

  const recommendationRef = useRef();

  useEffect(() => {
    if (query === "") {
      setPatients([]);
      return;
    }

    if (query.length < 2) return;

    setPatientsLoading(true);
    api.client.get("/patients", { params: { query: query } }).then((resp) => {
      setPatients(resp.data.patients);
      setPatientsLoading(false);
    });
  }, [api.client, query]);

  useEffect(() => {
    api.client.get("/recent_searches").then((resp) => {
      setRecentPatients(resp.data.patients);
    });
  }, [api.client]);

  const handleAdd = (patient) => {
    const params = { recommendation_id: recommendation.id };

    if (!!recommendation.content && recommendation.content !== recommendationRef.current.value) {
      params.content = recommendationRef.current.value;
    } else if (
      !!recommendation.recommendation?.content &&
      recommendation.recommendation?.content !== recommendationRef.current.value
    ) {
      params.content = recommendationRef.current.value;
    }

    api.client.post(`/patients/${patient.id}/recommendations`, params).then((resp) => {
      if (resp.status === 204) {
        posthog?.capture("assign_recommendation", {
          patient_id: patient.id,
          recommendation_id: recommendation.id,
          content: params.content,
          already_added: true,
        });
        toast.warning("Action already added to patient.");
      } else {
        posthog?.capture("assign_recommendation", {
          patient_id: patient.id,
          recommendation_id: recommendation.id,
          content: params.content,
          already_added: false,
        });
        navigate(`/patients/${patient.id}`);
      }
    });
  };

  return (
    <Dialog
      className="relative z-10"
      open={open}
      onClose={() => {
        setQuery("");
        onClose();
      }}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-slate-500 bg-opacity-25 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="mt-20 sm:mt-0 fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <DialogPanel
          transition
          className="mx-auto max-w-3xl transform divide-y divide-slate-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <Combobox>
            {({ activeOption }) => (
              <>
                <div className="relative">
                  <RiSearch2Line className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-slate-400" />
                  <ComboboxInput
                    autoFocus
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-slate-900 placeholder:text-slate-400 focus:ring-0 sm:text-sm"
                    placeholder="Search for patient..."
                    onChange={(event) => setQuery(event.target.value)}
                    onBlur={() => setQuery("")}
                  />
                </div>
                <div className="flex w-full items-center px-3 py-2">
                  <EditRecommendation recommendation={recommendation} ref={recommendationRef} />
                </div>

                {(query === "" || patients.length > 0) && (
                  <ComboboxOptions as="div" static hold className="flex transform-gpu divide-x divide-slate-100">
                    <div className="max-h-[500px] min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4">
                      {query === "" && recentPatients.length > 0 && (
                        <h2 className="mb-4 mt-2 text-xs font-semibold text-slate-500">Recent Patients</h2>
                      )}
                      <div className="-mx-2 text-sm text-slate-700">
                        {(query === "" ? recentPatients : patients).map((patient) => (
                          <ComboboxOption
                            as="div"
                            key={patient.id}
                            value={patient}
                            className="group flex cursor-default select-none items-center rounded-md p-2 data-[focus]:bg-slate-100 data-[focus]:text-slate-900"
                          >
                            <AddToPatientItem patient={patient} recommendation={recommendation} onAdd={handleAdd} />
                          </ComboboxOption>
                        ))}
                      </div>
                    </div>

                    {activeOption && (
                      <div className="flex w-1/2 flex-none flex-col divide-y divide-slate-100 overflow-y-auto">
                        <div className="flex-none p-2 text-center">
                          {activeOption.photo ? (
                            <img src={activeOption.photo} alt="" className="mx-auto h-16 w-16 rounded-full" />
                          ) : (
                            <span className="inline-block overflow-hidden h-16 w-16 rounded-full bg-slate-100">
                              <svg className="h-full w-full text-slate-300" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                              </svg>
                            </span>
                          )}
                          <h2 className="mt-3 font-semibold text-slate-900">{activeOption.name}</h2>
                        </div>
                        <div className="flex flex-auto flex-col justify-between p-6">
                          <ul className="divide-y divide-slate-100 max-h-[300px] overflow-y-auto overflow-x-hidden">
                            {!!activeOption.recommendations &&
                              activeOption.recommendations.map((r) => (
                                <li
                                  key={r.id}
                                  className="relative flex items-center justify-between px-2 py-2 hover:bg-slate-50 hover:rounded-md hover:shadow-sm hover:ring-1 hover:ring-slate-200 transition-colors duration-200"
                                >
                                  <div className="flex text-sm leading-6 text-slate-900 items-center space-x-2">
                                    <div>
                                      {r?.dynamic_content ||
                                        r?.content ||
                                        r?.recommendation?.dynamic_content ||
                                        r?.recommendation?.content}
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </ul>
                          <button
                            type="button"
                            data-tooltip-id="add-patient-recommendation"
                            data-tooltip-content={toolTipContent}
                            onClick={() => handleAdd(activeOption)}
                            className="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Assign to Patient
                          </button>
                        </div>
                      </div>
                    )}
                  </ComboboxOptions>
                )}

                {query !== "" && patients.length === 0 && (
                  <div className="px-6 py-14 text-center text-sm sm:px-14">
                    {patientsLoading ? (
                      <LoadingSpinner color="text-indigo-600" />
                    ) : (
                      <>
                        <RiUserLine className="mx-auto h-6 w-6 text-slate-400" aria-hidden="true" />
                        <p className="mt-4 font-semibold text-slate-900">No patients found</p>
                        <p className="mt-2 text-slate-500">
                          We couldn't find any patients with that term. Please try again.
                        </p>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </Combobox>
        </DialogPanel>
        <Tooltip id="add-patient-recommendation" />
      </div>
    </Dialog>
  );
};

export default AddToPatientDialog;
