import { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { ActionPlanProvider } from "../providers/ActionPlanProvider";
import { ApiContext } from "../providers/ApiProvider";

import LoadingSpinner from "../components/UI/LoadingSpinner";
import PatientDashboard from "../components/Patients/PatientDashboard";
import PatientDetailsHeader from "../components/Patients/Details/PatientDetailsHeader";

const PatientsDetailsPage = () => {
  const api = useContext(ApiContext);

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [patient, setPatient] = useState();

  useEffect(() => {
    api.client
      .get(`/patients/${id}`)
      .then((resp) => {
        setPatient(resp.data.patient);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [api.client, id]);

  const handleUpdateDetails = (details) => {
    setPatient(details);
  };

  return (
    <div className="mx-auto w-full max-w-[2000px]">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="mt-2 lg:mt-5 lg:mx-5 flex justify-center">
            <PatientDetailsHeader patient={patient} onUpdateDetails={handleUpdateDetails} />
          </div>
          <ActionPlanProvider>
            <PatientDashboard patientId={id} />
          </ActionPlanProvider>
        </>
      )}
    </div>
  );
};

export default PatientsDetailsPage;
