import { useContext, useState } from "react";

import { Link, Navigate, Outlet } from "react-router-dom";

import Cookies from "universal-cookie";

import { Tooltip } from "react-tooltip";
import { Toaster } from "sonner";

import PostHogPageviewTracker from "../../utils/PostHogPageviewTracker";

import { AuthContext } from "../../providers/AuthProvider";

import Footer from "../UI/Footer";
import Banner from "../UI/Banner";

import AnonTopNav from "./AnonTopNav";

const AnonLayout = () => {
  const auth = useContext(AuthContext);

  const cookies = new Cookies(null, { path: "/" });

  const [hideBetaBanner, setHideBetaBanner] = useState(cookies.get("hideCookieBanner") || false);

  if (auth.isLoggedIn()) {
    return <Navigate to="/dashboard" replace />;
  }

  const handleHideBanner = () => {
    cookies.set("hideCookieBanner", true);
    setHideBetaBanner(true);
  };

  return (
    <>
      <PostHogPageviewTracker />
      {!hideBetaBanner && (
        <Banner onDismiss={handleHideBanner}>
          <span className="max-w-7xl">
            We use cookies to understand how you use the product and help us improve it{" "}
            <Link to="/cookies" className="hover:underline italic">
              Learn more
            </Link>
            .
            <button
              type="button"
              onClick={handleHideBanner}
              className="z-20 ml-2 rounded bg-white px-4 py-1 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-100"
            >
              Ok
            </button>
          </span>
        </Banner>
      )}
      <div className="bg-white flex flex-col h-screen justify-between">
        <AnonTopNav />
        <main>
          <Toaster richColors position="top-center" />
          <Outlet />
        </main>
        <Footer />
        <Tooltip id="anon-tooltip" />
      </div>
    </>
  );
};

export default AnonLayout;
