import { useEffect, useRef, useState } from "react";

import { isPhoneValid, isEmailValid } from "../../utils/validators";

import Input from "../UI/Input";

const GeneralTab = (props) => {
  const { onCancel, onUpdate, user } = props;

  // Used for managing uploaded file preview
  const [photoFile, setPhotoFile] = useState(null);
  const [photo, setPhoto] = useState(user?.photo || null);

  const [firstName, setFirstName] = useState(user.first_name || "");
  const [lastName, setLastName] = useState(user.last_name || "");
  const [email, setEmail] = useState(user.email || "");
  const [phone, setPhone] = useState(user.phone || "");
  const [title, setTitle] = useState(user.title || "");

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const photoRef = useRef();

  useEffect(() => {
    setFirstNameError(firstName.length < 1);
  }, [firstName]);

  useEffect(() => {
    setLastNameError(lastName.length < 1);
  }, [lastName]);

  useEffect(() => {
    setEmailError(email.length < 1 && !isEmailValid(email));
  }, [email]);

  useEffect(() => {
    if (phone.length > 0) {
      setPhoneError(!isPhoneValid(phone));
    }
  }, [phone]);

  useEffect(() => {
    setIsFormValid(!firstNameError && !lastNameError && !emailError && !phoneError);
  }, [emailError, firstNameError, lastNameError, phoneError]);

  const handleRemovePhoto = () => {
    setPhoto(null);
    setPhotoFile(undefined);
  };

  const handleChangePhoto = () => {
    photoRef.current.click();
  };

  const onPhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      setPhotoFile(file);

      let reader = new FileReader();
      reader.onload = (e) => {
        setPhoto(URL.createObjectURL(file));
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUpdate = () => {
    onUpdate({ first_name: firstName, last_name: lastName, email, phone, title, photo: photoFile });
  };

  return (
    <>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="mt-6 space-y-3">
          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
            <div>
              <div className="-m-1 flex">
                <div className="group inline-flex overflow-hidden rounded-lg border-4 border-white">
                  <input
                    type="file"
                    onChange={onPhotoChange}
                    id="photo-file"
                    name="photo-file"
                    ref={photoRef}
                    className="hidden"
                  />
                  <div className="relative">
                    {photo ? (
                      <img
                        className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                        src={photo}
                        alt={`${firstName} ${lastName}`}
                      />
                    ) : (
                      <span className="inline-block overflow-hidden h-24 w-24 sm:h-40 sm:w-40 lg:h-48 lg:w-48 bg-gray-100">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    )}
                    <div className="group-hover:block hidden absolute top-14 left-14">
                      <button
                        onClick={handleChangePhoto}
                        type="button"
                        className="inline-flex items-center rounded-md border border-transparent opacity-80 bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Change
                      </button>
                      {photo && (
                        <button
                          onClick={handleRemovePhoto}
                          type="button"
                          className="mt-5 opacity-80 inline-flex items-center rounded-md border border-transparent bg-red-400 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Input
            type="text"
            name="firstName"
            placeholder="Enter First Name"
            label="First Name"
            value={firstName}
            isValid={!firstNameError}
            onChange={(val) => setFirstName(val)}
            errorMessage="First Name is required!"
          />

          <Input
            type="text"
            name="lastName"
            placeholder="Enter Last Name"
            label="Last Name"
            value={lastName}
            isValid={!lastNameError}
            onChange={(val) => setLastName(val)}
            errorMessage="Last Name is required!"
          />

          <Input
            type="email"
            name="email"
            placeholder="Enter your email"
            label="Email"
            value={email}
            isValid={!emailError}
            onChange={(val) => setEmail(val)}
            errorMessage="Email is required!"
          />

          <Input
            type="text"
            name="phone"
            placeholder="Enter your phone number"
            label="Phone"
            value={phone}
            isValid={!phoneError}
            onChange={(val) => setPhone(val)}
            errorMessage="Please enter a valid phone number!"
          />

          <Input
            type="text"
            name="title"
            placeholder="Enter your title"
            label="Title"
            value={title}
            isValid={true}
            onChange={(val) => setTitle(val)}
            errorMessage=""
          />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={onCancel}
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleUpdate}
            disabled={!isFormValid}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default GeneralTab;
