import {
  RiCapsuleFill,
  RiGoblet2Fill,
  RiHotelBedLine,
  RiMentalHealthLine,
  RiRestaurantLine,
  RiRunFill,
  RiSurveyLine,
  RiTeamLine,
} from "@remixicon/react";

const pillars = {
  nutrition: {
    name: "Diet and Nutrition",
    label: "Nutrition",
    icon: RiRestaurantLine,
    color: "green",
    description:
      "Consuming a fiber-filled, nutrient-dense, antioxidant-rich eating pattern based predominantly on a variety of minimally processed vegetables, fruits, whole grains, legumes, nuts and seeds.",
  },
  activity: {
    name: "Physical and Cognitive Activity",
    label: "Activity",
    icon: RiRunFill,
    color: "yellow",
    description: "Engaging in regular and consistent physical activity.",
  },
  sleep: {
    name: "Sleep",
    label: "Sleep",
    icon: RiHotelBedLine,
    color: "blue",
    description: "Striving for 7-9 hours of high-quality sleep, allowing the body to reset and recover.",
  },
  toxic: {
    name: "Avoiding Toxic Substances and Environments",
    label: "Toxic Exposures",
    icon: RiGoblet2Fill,
    color: "orange",
    description:
      "Reducing or eliminating the consumption of or exposure to any substances that cause harm through toxicity, addiction, physical damage, or adverse side effects.",
  },
  emotions: {
    name: "Emotional Well-being",
    label: "Emotions",
    icon: RiMentalHealthLine,
    color: "purple",
    description:
      "Incorporating stress-reducing behaviors may be difficult in modern society but is essential for whole-person health. ",
  },
  connect: {
    name: "Social Connectedness and Purpose",
    label: "Connectedness",
    icon: RiTeamLine,
    color: "red",
    description:
      "Strengthening and maintaining relationships and connections with others that bring meaning and purpose to life.",
  },
  medications: {
    name: "Medications and Supplements",
    label: "Medications",
    icon: RiCapsuleFill,
    color: "stone",
    description: "",
  },
  testing: {
    name: "Testing and Screening",
    label: "Screening",
    icon: RiSurveyLine,
    color: "cyan",
    description: "",
  },
};

function getPillar(name) {
  return Object.values(pillars).find((p) => p.name === name);
}

export { getPillar, pillars };
