import { createBrowserRouter, Outlet } from "react-router-dom";

import AnonLayout from "./components/Layout/AnonLayout";
import AuthedLayout from "./components/Layout/AuthedLayout";

import AppointmentsPage from "./pages/AppointmentsPage";
import ClinicPage from "./pages/ClinicPage";
import DashboardPage from "./pages/DashboardPage";
import DebugPage from "./pages/DebugPage";
import ErrorPage from "./pages/ErrorPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import NewUserPage from "./pages/NewUserPage";
import PatientsPage from "./pages/PatientsPage";
import PrivacyPage from "./pages/PrivacyPage";
import RegisterPage from "./pages/RegisterPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import TermsPage from "./pages/TermsPage";
import UserProfilePage from "./pages/UserProfilePage";

import Stripe from "./components/Registration/Stripe";
import PatientsDetailsPage from "./pages/PatientsDetailsPage";
import CookiePage from "./pages/CookiePage";

export function buildRoutes() {
  return createBrowserRouter([
    {
      path: "privacy",
      element: <PrivacyPage />,
    },
    {
      path: "terms",
      element: <TermsPage />,
    },
    {
      path: "cookies",
      element: <CookiePage />,
    },
    {
      path: "debug",
      element: <DebugPage />,
    },
    {
      path: "/",
      element: <Outlet />,
      errorElement: <ErrorPage />,
      children: [
        {
          children: [
            {
              element: <AnonLayout />,
              children: [
                {
                  index: true,
                  element: <LandingPage />,
                },
                {
                  path: "login",
                  element: <LoginPage />,
                },
                {
                  path: "register",
                  element: <RegisterPage />,
                },
                {
                  path: "forgot_password",
                  element: <ForgotPasswordPage />,
                },
                {
                  path: "reset_password",
                  element: <ResetPasswordPage />,
                },
                {
                  path: "new_user",
                  element: <NewUserPage />,
                },
              ],
            },
            {
              element: <AuthedLayout />,
              children: [
                {
                  children: [
                    {
                      path: "dashboard",
                      element: <DashboardPage />,
                    },
                    {
                      path: "clinic",
                      element: <ClinicPage />,
                    },
                    {
                      path: "appointments",
                      element: <AppointmentsPage />,
                    },
                    {
                      path: "patients",
                      children: [
                        {
                          index: true,
                          element: <PatientsPage />,
                        },
                        {
                          path: ":id",
                          element: <PatientsDetailsPage />,
                        },
                      ],
                    },
                    {
                      path: "/stripe",
                      element: <Stripe />,
                    },
                    {
                      path: "profile",
                      element: <UserProfilePage />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ]);
}
