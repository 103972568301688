import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useWindowScroll } from "@uidotdev/usehooks";

import { RiArrowUpSLine } from "@remixicon/react";

import ProgressRing from "../components/UI/ProgressRing";

import About from "../components/LandingPage/About";
import Contact from "../components/LandingPage/Contact";
import Features from "../components/LandingPage/Features";
import Hero from "../components/LandingPage/Hero";
import Newsletter from "../components/LandingPage/Newsletter";
import Pricing from "../components/LandingPage/Pricing";

const LandingPage = () => {
  const [{ y }, scrollTo] = useWindowScroll();

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const bottomPosition = document.documentElement.scrollHeight - window.innerHeight;

  return (
    <>
      <Hero />
      <Features />
      <Pricing />
      <About />
      <Contact />
      <Newsletter />

      <button
        type="button"
        onClick={() => scrollTo(0, 0)}
        className="fixed z-50 bottom-10 right-[2%] rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <ProgressRing radius={16} stroke={2} progress={Math.round((y / bottomPosition) * 100)} color="#7dd3fc" />
        <RiArrowUpSLine aria-hidden="true" className="h-8 w-8" />
      </button>
    </>
  );
};

export default LandingPage;
